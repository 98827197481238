<template>
  <div>
    <div class="d-flex align-center justify-space-between mb-6">
      <h3 class="display-1">
        {{ editing ? "Edit" : "Create A New" }} Inventory
      </h3>
      <div>
        <v-btn color="primary" text @click="dialog = true" class="me-2"
          ><v-icon>mdi-plus</v-icon>Create New Source</v-btn
        >

        <v-btn color="primary" to="/inventory" exact
          ><v-icon left>mdi-arrow-left</v-icon> Back to Inventorys</v-btn
        >
      </div>
    </div>
    <!-- add new source pop up modal -->
    <div v-if="dialog">
      <v-dialog
        v-model="dialog"
        width="30%"
        transition="dialog-bottom-transition"
      >
        <v-card tile>
          <!-- dialog toolbar -->
          <v-toolbar
            dark
            color="primary"
            dense
            flat
            style="position: sticky; top: 0; width: 100%; z-index: 500"
          >
            <v-toolbar-title>
              <strong>Add new source</strong>
              <span class="ml-6"> </span>
            </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                depressed
                color="primary"
                dark
                :loading="loadingSource"
                :disabled="loadingSource"
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text class="text--primary mt-6">
            <!-- form -->
            <v-form>
              <v-row style="justify-content: center">
                <v-col cols="9">
                  <v-text-field
                    v-model="source"
                    :loading="loadingSource"
                    :disabled="loadingSource"
                    label="Enter Source"
                    :rules="[(v) => !!v || 'Field Required']"
                    required
                    hide-details="auto"
                  ></v-text-field> </v-col
              ></v-row>
              <v-row style="justify-content: center">
                <v-col cols="3">
                  <v-btn
                    depressed
                    :loading="loadingSource"
                    color="primary"
                    :disabled="loadingSource || source == ''"
                    style="width: inherit"
                    @click="saveSource"
                  >
                    Submit
                  </v-btn>
                </v-col></v-row
              ></v-form
            ></v-card-text
          ></v-card
        ></v-dialog
      >
    </div>
    <v-row>
      <v-col cols="12">
        <v-card outlined class="d-md-flex flex-md-column" :loading="loading">
          <v-card-text class="text--primary">
            <v-form ref="form" @submit.prevent="save">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="inventoryProperty.resortName"
                    :loading="loading"
                    :disabled="loading"
                    label="Resort Name"
                    :rules="[(v) => !!v || 'Field Required']"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="inventoryProperty.destination"
                    :loading="loading"
                    :disabled="loading"
                    :rules="[(v) => !!v || 'Field Required']"
                    outlined
                    label="Resort Destination"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    v-model="inventoryProperty.resortAddress"
                    :loading="loading"
                    :disabled="loading"
                    :rules="[(v) => !!v || 'Field Required']"
                    outlined
                    label="Resort Address"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="inventoryProperty.id"
                    :loading="loading"
                    :disabled="loading || editing"
                    :rules="[(v) => !!v || 'Field Required']"
                    outlined
                    label="Resort Id *"
                    hint="Lowercase, no spaces or dashes (ex: 1234)"
                    persistent-hint
                    :error="resortIdError"
                    :error-messages="resortIdErrorMessage"
                    @blur="checkResortId"
                  ></v-text-field>
                </v-col>

                <v-col cols="3 ">
                  <v-select
                    v-model="inventoryProperty.source"
                    :items="sourceItems"
                    :disabled="loading || editing"
                    label="Source"
                    :rules="[(v) => !!v || 'Field Required']"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="inventoryProperty.overview"
                    :loading="loading"
                    :disabled="loading"
                    :rules="[(v) => !!v || 'Field Required']"
                    outlined
                    label="Resort Overview"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" v-if="checkField('unitInfo')">
                  <v-text-field
                    v-model="inventoryProperty.unitInfo"
                    :loading="loading"
                    :disabled="loading"
                    label="Resort Unit Info"
                    outlined
                    hide-details="auto"
                    rows="4"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-toolbar-title class="mt-2 mb-2">
                    <strong>Resort Images</strong>
                    <span class="ml-6"> </span>
                  </v-toolbar-title>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-btn
                    color="primary"
                    :style="{ width: `100%` }"
                    @click="$refs.fileInput.click()"
                    :loading="loading"
                  >
                    Upload images
                    <input
                      ref="fileInput"
                      type="file"
                      accept=".jpg, .png, .gif"
                      style="display: none"
                      multiple
                      @change="onAddImg($event.target.files)"
                    />
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  v-for="(item, index) in inventoryProperty.images"
                  :key="index"
                >
                  <v-card outlined>
                    <v-img :src="item.downloadUrl" cover height="300">
                      <!-- <div
                        v-if="item.addOverlayText && item.overlayText"
                        class="text-center title white--text px-4 py-2 text-uppercase"
                        style="
                          background: rgba(0, 0, 0, 0.75);
                          position: absolute;
                          bottom: 0;
                          left: 0;
                          width: 100%;
                        "
                      >
                        {{ item.overlayText }}
                      </div> -->
                    </v-img>
                    <v-card-text class="text--primary">
                      <v-row>
                        <v-col cols="12" class="body-1 font-weight-medium">
                          Name:
                          <span>{{ item.name }}</span>
                        </v-col>
                        <!-- add overlay text -->
                        <!-- <v-col cols="12">
                          <v-select
                            dense
                            :items="[true, false]"
                            v-model.trim="item.addOverlayText"
                            label="Add Overlay Text *"
                            :rules="[
                              (v) => !!v || v === false || 'Field Required',
                            ]"
                          ></v-select>
                        </v-col> -->
                        <!-- add overlay text -->
                        <!-- overlay text -->
                        <!-- <v-col cols="12" v-if="item.addOverlayText">
                          <v-text-field
                            label="Overlay Text *"
                            v-model.trim="item.overlayText"
                            dense
                            :rules="[(v) => !!v || 'Field Required']"
                            counter
                            hint="Max 45 Characters"
                            @keypress="limitCharacterCount($event, 45)"
                            persistent-hint
                          ></v-text-field>
                        </v-col> -->
                        <!-- overlay text -->
                        <!-- download URL -->
                        <v-col cols="12" class="body-1">
                          <span class="d-block mb-2 font-weight-medium"
                            >Image URL:</span
                          >
                          <code v-html="item.downloadUrl" />
                        </v-col>
                        <!-- download URL -->
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        color="primary"
                        depressed
                        @click="onSortImg(index, index - 1)"
                        :class="index == 0 ? 'd-none' : ''"
                      >
                        <v-icon>mdi-chevron-left</v-icon>
                      </v-btn>
                      <v-btn
                        color="primary"
                        depressed
                        @click="onSortImg(index, index + 1)"
                        :class="
                          item.images
                            ? index == item.images.length - 1
                              ? 'd-none'
                              : ''
                            : ''
                        "
                      >
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                      <v-spacer />
                      <v-btn
                        color="error"
                        depressed
                        @click="onDeleteImg(index)"
                      >
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-divider class="mt-5 mb-6" />
                </v-col>
              </v-row>

              <v-toolbar-title class="mt-6 mb-6">
                <strong>Resort Information</strong>
                <span class="ml-6"> </span> </v-toolbar-title
              ><v-row>
                <v-col cols="6" v-if="checkField('activities')">
                  <v-textarea
                    v-model="inventoryProperty.activities"
                    :loading="loading"
                    :disabled="loading"
                    outlined
                    label="Activities"
                    hide-details="auto"
                    :deletable-chips="true"
                    rows="3"
                    hint="Click (Enter) key to enter a new activity"
                    persistent-hint
                    multiple
                    chips
                    class="combobox-field"
                  ></v-textarea>
                </v-col>

                <v-col cols="6" v-if="checkField('airportInfo')">
                  <v-textarea
                    v-model="inventoryProperty.airportInfo"
                    :loading="loading"
                    :disabled="loading"
                    label="Airport Information"
                    hide-details="auto"
                    hint="Enter airpot information"
                    persistent-hint
                    :deletable-chips="true"
                    rows="3"
                    outlined
                    multiple
                    chips
                    class="combobox-field"
                  ></v-textarea>
                </v-col>
                <v-col cols="6" v-if="checkField('otherInfo')">
                  <v-textarea
                    v-model="inventoryProperty.otherInfo"
                    hint="Enter other info"
                    persistent-hint
                    :loading="loading"
                    :disabled="loading"
                    label="Other Information"
                    hide-details="auto"
                    :deletable-chips="true"
                    rows="3"
                    outlined
                    multiple
                    chips
                    class="combobox-field"
                  ></v-textarea>
                </v-col>
                <v-col cols="6" v-if="checkField('restaurants')">
                  <v-textarea
                    v-model="inventoryProperty.restaurants"
                    :loading="loading"
                    hint="Enter restaurants and dinings info"
                    persistent-hint
                    :deletable-chips="true"
                    :disabled="loading"
                    label="Restaurants & Dining"
                    hide-details="auto"
                    rows="3"
                    outlined
                    multiple
                    chips
                    class="combobox-field"
                  ></v-textarea>
                </v-col>
                <v-col cols="6" v-if="checkField('restrictions')">
                  <v-textarea
                    v-model="inventoryProperty.restrictions"
                    :loading="loading"
                    hint="Enter resort restrictions policy"
                    persistent-hint
                    :disabled="loading"
                    :deletable-chips="true"
                    label="Policy Restrictions"
                    hide-details="auto"
                    rows="3"
                    multiple
                    outlined
                    chips
                    class="combobox-field"
                  >
                  </v-textarea>
                </v-col>
                <v-col cols="6" v-if="checkField('checkInInfo')">
                  <v-textarea
                    v-model="inventoryProperty.checkInInfo"
                    :loading="loading"
                    hint="Enter check in & out informations"
                    persistent-hint
                    :disabled="loading"
                    :deletable-chips="true"
                    label="Check In/Out Information"
                    hide-details="auto"
                    rows="3"
                    multiple
                    outlined
                    chips
                    class="combobox-field"
                  >
                  </v-textarea>
                </v-col>
                <v-col cols="6" v-if="checkField('amenities')">
                  <v-select
                    v-model="inventoryProperty.amenities"
                    :items="amenities"
                    hint="Enter check in & out informations"
                    chips
                    label="Amenities"
                    :deletable-chips="true"
                    multiple
                    outlined
                    class="custom-select"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-divider class="mt-5 mb-6" />
                </v-col>
              </v-row>

              <!-- surcharges -->
              <v-row>
                <v-toolbar flat dense>
                  <v-toolbar-title><strong>Surcharges</strong></v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <v-switch
                      class="mx-8 mt-3"
                      label="Add Peak Season 1"
                      v-model="inventoryProperty.hasPeakSeasonSurcharge1"
                    ></v-switch>
                    <v-switch
                      class="mx-8 mt-3"
                      label="Add Peak Season 2"
                      v-model="inventoryProperty.hasPeakSeasonSurcharge2"
                    ></v-switch>
                    <v-switch
                      class="mx-8 mt-3"
                      label="Add High Occupancy"
                      v-model="inventoryProperty.hasHighOccupancySurcharge"
                    ></v-switch>
                  </v-toolbar-items>
                </v-toolbar>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  v-if="inventoryProperty.hasPeakSeasonSurcharge1"
                >
                  <p>Select 2 dates to create a range</p>
                  <v-date-picker
                    v-model="inventoryProperty.peakSeasonSurcharge1Range"
                    range
                    full-width
                    dense
                    class="border"
                    style="min-height: 450px"
                  ></v-date-picker>
                  <span class="d-block mt-4">
                    {{ inventoryProperty.peakSeasonSurcharge1Range }}</span
                  >
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  v-if="inventoryProperty.hasPeakSeasonSurcharge2"
                >
                  <p>Select 2 dates to create a range</p>
                  <v-date-picker
                    v-model="inventoryProperty.peakSeasonSurcharge2Range"
                    range
                    class="border"
                    dense
                    full-width
                    style="min-height: 450px"
                  ></v-date-picker>
                  <span class="d-block mt-4">
                    {{ inventoryProperty.peakSeasonSurcharge2Range }}</span
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                  v-if="
                    inventoryProperty.hasPeakSeasonSurcharge1 ||
                      inventoryProperty.hasPeakSeasonSurcharge2
                  "
                >
                  <v-text-field
                    label="Peak Season Surcharge Amount"
                    :rules="[(v) => !!v || 'Field Required']"
                    dense
                    v-model="inventoryProperty.peakSeasonSurchargeAmount"
                    hint="Whole numbers only. No decimals, etc. (500)"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                  v-if="inventoryProperty.hasHighOccupancySurcharge"
                >
                  <v-text-field
                    label="High Occupancy Surcharge Amount"
                    :rules="[(v) => !!v || 'Field Required']"
                    dense
                    v-model="inventoryProperty.highOccupancySurchargeAmount"
                    hint="Whole numbers only. No decimals, etc. (500)"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field
                    dense
                    v-model="inventoryProperty.taxesFeesPercentage"
                    label="Tax &amp; Fees Percentage *"
                    :rules="[(v) => v === 0 || !!v || 'Field Required']"
                    hint="Whole numbers only. No decimals, symbols etc. (500)"
                    persistent-hint
                  ></v-text-field
                ></v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field
                    dense
                    v-model="inventoryProperty.gardenUnitUpgrade"
                    label="Garden Unit Upgrade"
                    hint="Whole numbers only. No decimals, etc. (500)"
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field
                    dense
                    v-model="inventoryProperty.costOfExtras"
                    label="Cost of Extras *"
                    :rules="[(v) => v === 0 || !!v || 'Field Required']"
                    hint="Whole numbers only. No decimals, etc. (500)"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field
                    dense
                    v-model="inventoryProperty.minimumProfitAccepted"
                    label="Minimum Profit Accepted *"
                    :rules="[(v) => !!v || 'Field Required']"
                    hint="Whole numbers only. No decimals, etc. (500)"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field
                    dense
                    v-model="inventoryProperty.commissionsPaidPercentage"
                    label="Commissions Paid Percentage *"
                    :rules="[(v) => !!v || 'Field Required']"
                    hint="Whole numbers only. No decimals, etc. (500)"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-divider class="mt-3" />
                </v-col>
              </v-row>
              <!-- surcharges -->
            </v-form>
          </v-card-text>
          <v-card-actions class="sticky-v-card-actions">
            <v-spacer />
            <!-- submit button -->
            <v-btn
              color="primary"
              depressed
              @click="save"
              :loading="loading"
              :disabled="loading"
            >
              {{ editing ? "Save" : "Create" }} Inventory</v-btn
            >
            <!-- submit button -->
          </v-card-actions></v-card
        ></v-col
      ></v-row
    >
  </div>
</template>
<script>
import { mainApp, portalsApp } from "../../firebase";
import moment from "moment";
const sourceDoc = portalsApp.firestore().collection("sources");
export default {
  mounted() {
    this.getAllSources();
  },
  updated() {
    this.getAllSources();
  },
  data: () => ({
    dialog: false,
    loadingSource: false,
    source: "",
    sources: [],
    editing: false,
    resortIdError: false,
    resortIdErrorMessage: "",
    inventoryProperty: { image: "", images: [] },
    menu2: false,
    menu1: false,
    amenities: [
      "Air Conditioning (In Units)",
      "Amusement Parks",
      "Archery",
      "ATM / Banking",
      "Babysitting Referral",
      "Bar / Cocktail Lounge",
      "Beach",
      "Beach Facilities",
      "Bicycling",
      "Billiards / Pool Table",
      "Boat Marina / Launching",
      "Boating",
      "Bowling Alley",
      "Business Center",
      "Car Rental",
      "Casino / Gambling",
      "Childcare",
      "Children Activities",
      "Cinema / Movie Theater",
      "Clubhouse",
      "Concierge Desk/Services",
      "Conference Facilities",
      "Cooking Facilities (In Units)",
      "Cross Country Skiing",
      "Cultural Sites",
      "Currency Exchange",
      "Day Spa",
      "Downhill Skiing",
      "Dry Cleaning / Laundry Service",
      "DVD or VCR (In Units)",
      "Entertainment (Live)",
      "Entertainment / Nightlife",
      "Exercise Room, Equipped",
      "Fireplace (In Units)",
      "Fishing",
      "Fitness Center",
      "Game room(Onsite)",
      "Gift Shops or Newsstand",
      "Golf",
      "Golf - Miniature",
      "Grocery / Convenience Store",
      "Helicopter / Airplane Sightseeing",
      "Hiking",
      "Historical Areas",
      "Horseback Riding",
      "Hunting",
      "Ice Skating",
      "Internet access in public areas (fees may apply) (Wireless Internet Access)",
      "Jacuzzi/Hot tub(Onsite)",
      "Jet Skiing",
      "Lake",
      "Laundry facilities(Onsite)",
      "Local Tourism",
      "Lock-Off",
      "Medical facility(2 Miles)",
      "Movie Rental",
      "Natural Sites",
      "Nightclub",
      "Parks / Zoos",
      "Ping Pong",
      "Playground",
      "Post Office",
      "Racquetball",
      "Restaurant",
      "Running Track / Path",
      "Safari",
      "Safe-Deposit Box (In Units)",
      "Sailing / Rentals",
      "Salon",
      "Sauna(Yes)",
      "Scuba Diving",
      "Shopping",
      "Ski School",
      "Snack Bar",
      "Snorkeling",
      "Snowboard Lessons",
      "Spa(2 Miles)",
      "Sports Counts / Grounds",
      "Steam Room",
      "Swimming pool(Onsite)",
      "Television in Lobby",
      "Tennis",
      "Theme Park",
      "Volleyball",
      "Water Park",
      "Water Skiing",
      "Wheelchair Accessible",
      "Whirlpool Spa / Hot Tub",
      "Wi-Fi Access",
      "Windsurfing",
      "Wineries",
      "Daily Housekeeping",
      "Tour Desk",
      "Room Service",
      "Packed Lunches",
      "Luggage Storage",
      "Bicycle Rental",
      "Smoke-Free Property",
      "Multilingual Staff",
      "Languages Spoken Onsite (English, Spanish, German, Italian, French)",
      "Languages Spoken Onsite (English, Spanish, German, Italian, Swedish, Dutch, Finnish, French)",
      "Dishwasher",
      "Cooker",
      "Microwave",
      "Washing Machine",
      "Garden",
      "Fridge",
      "Marina",
      "ATM/banking (Nearby)",
      "Billiards or pool table (Yes)",
      "Business center (Yes)",
      "Car Rental (Nearby)",
      "Concierge desk/services (Yes)",
      "Currency exchange (Nearby)",
      "Fitness Center (Onsite)",
    ],
    sourceItems: [
      "tripbeat",
      "castlemartyr",
      "dreamweeks",
      "gardens at west maui",
      "kahana villa vacation club",
      "knightsbrook",
      "maui banyan vacation club",
      "maui beach vacation club",
      "sands of kahana resort",
      "grand ferdinand vienna",
      "ambassador hotel vienna",
      "vienna marriot",
      "exe international palace rome",
      "hotel mancino 12 rome",
      "napolean hotel rome",
      "paris marriot opera ambassador",
      "golden tulip hotel washington opera paris",
      "lempire paris",
      "grandior hotel prague",
      "hilton prague old town",
      "michelangelo grand hotel prague",
      "paris las vegas",
      "mandalay bay las vegas",
      "caesars palace las vegas",
      "mirage las vegas",
      "mgm grand las vegas",
      "cosmopolitan las vegas",
      "bayshore villas bali",
      "holston house nashville",
      "renaissance nashville",
      "gaylord opryland resort",
      "the inn at opryland",
      "millennium maxwell house",
      "the capitol hotel an ascend hotel",
      "the guest house at graceland",
      "hu hotel",
      "crowne plaza hotel memphis downtown",
      "sheraton memphis downtown",
      "kahana falls resort",
      "pono kai resort",
      "the makai club",
      "johnstown estate",
      "hotel hendricks macys",
      "hotel hendricks",
      "the manhattan at times square hotel macys",
      "the manhattan at times square hotel",
      "row nyc macys",
      "row nyc",
      "hotel riu plaza new york times square macys",
      "hotel riu plaza new york times square",
      "royal decameron cornwall beach all inclusive",
      "bahia principe grand jamaica all inclusive",
      "east clare golf village",
      "sun peaks grand hotel",
      "be live collection punta cana all inclusive - adults only",
      "sunscape puerto plata dominican republic all inclusive",
      "occidental cozumel all inclusive",
      "the pinnacle lodge",
      "casa dorada cabo real",
      "the royal cancun",
      "indian palms vacation club",
      "palm canyon resort",
      "london hilton on park lane",
      "royal sonesta",
      "the westin cape coral resort at marina village",
      "the atlantic hotel and spa",
      "eden roc miami beach",
      "desert breezes resort DBR",
      "desert breezes resort DZ2",
      "westgate town center",
      "indian palms intervals",
      "blue tree resort at lake buena vista",
      "heyward mews holiday homes",
      "knightsbrook kbch",
      "embarc panorama",
      "panorama horsethief",
      "design suites buenos aires",
      "karma borgo di colleoli - standard unit",
      "karma borgo di colleoli - garden unit",
    ],
  }),
  beforeDestroy() {
    this.$store.state.inventoryId = { id: "", editing: false };
  },
  watch: {
    id: {
      immediate: true,
      async handler(id) {
        if (id) {
          this.constructInventoryItem(id);
        }
      },
    },
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    id() {
      this.editing = this.$store.state.inventoryId.editing;
      return this.$store.state.inventoryId.id;
    },
    getImage() {
      return this.inventoryProperty.image;
    },
  },
  methods: {
    async saveSource() {
      this.loadingSource = true;
      await portalsApp
        .firestore()
        .collection("sources")
        .doc(this.source)
        .set({ name: this.source });
      this.loadingSource = false;
      this.source = "";
      this.dialog = false;
    },
    async getAllSources() {
      await this.$bind("sources", sourceDoc);
      this.sourceItems = [
        ...this.sources.map((el) => el.name),
        ...this.sourceItems,
      ];
    },
    checkField(fieldName) {
      // switch (fieldName) {
      //   case "airportInfo":
      //     // Code for airport information
      //     // console.log("Airport information");
      //     return [
      //       "tripbeat",
      //       "hsi",
      //       "cmr",
      //       "dreamweeks",
      //       "apple",
      //       "hc",
      //       "manual",
      //     ].includes(this.inventoryProperty.source);
      //     break;

      //   case "amenities":
      //     // Code for amenities
      //     // console.log("Amenities");
      //     return [
      //       "tripbeat",
      //       "kbr",
      //       "mews",
      //       "hsi",
      //       "cmr",
      //       "dreamweeks",
      //       "apple",
      //       "hc",
      //       "manual",
      //     ].includes(this.inventoryProperty.source);
      //     break;

      //   case "checkInInfo":
      //     // Code for check-in information
      //     // console.log("Check-in information");
      //     return ["hsi", "tripbeat", "apple", "hc", "manual"].includes(
      //       this.inventoryProperty.source
      //     );
      //     break;

      //   case "unitInfo":
      //     // Code for unit information
      //     // console.log("Unit information");
      //     return ["tripbeat", "kbr", "mews", "manual"].includes(
      //       this.inventoryProperty.source
      //     );
      //     break;

      //   case "activities":
      //     // Code for activities
      //     // console.log("Activities");
      //     return ["tripbeat", "apple", "hc", "manual"].includes(
      //       this.inventoryProperty.source
      //     );
      //     break;

      //   case "restrictions":
      //     // Code for restrictions
      //     // console.log("Restrictions");
      //     return ["tripbeat", "apple", "hc", "manual"].includes(
      //       this.inventoryProperty.source
      //     );
      //     break;

      //   case "otherInfo":
      //     // Code for other information
      //     // console.log("Other information");
      //     return ["tripbeat", "apple", "hc", "manual"].includes(
      //       this.inventoryProperty.source
      //     );
      //     break;

      //   case "restaurants":
      //     // Code for restaurant
      //     // console.log("Restaurant");
      //     return ["tripbeat", "apple", "hc", "manual"].includes(
      //       this.inventoryProperty.source
      //     );
      //     break;

      //   default:
      //     return false;
      //     break;
      // }
      return true;
    },
    deleteImage() {
      // this.inventoryProperty.src = "";
      this.inventoryProperty.image = "";
    },
    onSortImg(from, to) {
      this.inventoryProperty.images.splice(
        to,
        0,
        this.inventoryProperty.images.splice(from, 1)[0]
      );
      // this.inventoryProperty.images.splice(
      //   to,
      //   0,
      //   this.inventoryProperty.src.splice(from, 1)[0]
      // );
    },
    onDeleteImg(index) {
      this.inventoryProperty.images.splice(index, 1);
      // this.inventoryProperty.src.splice(index, 1);
    },
    onAddImg(files) {
      if (files) {
        // console.log(files);
        for (let i = 0; i < files.length; i++) {
          let file = files[i];
          let img = {
            file: file,
            name: file.name.replace(" ", "-"),
            downloadUrl: URL.createObjectURL(file),
            dateSubmitted: new Date().toISOString(),
          };
          this.inventoryProperty.images.push(img);
          // this.inventoryProperty.src.push(img);
        }

        // console.log(file);
        // if (file) {
        //   let img = {
        //     file: file,
        //     name: file.name.replace(" ", "-"),
        //     downloadUrl: URL.createObjectURL(file),
        //     dateSubmitted: new Date().toISOString(),
        //   };
        //   this.inventoryProperty.src = [...this.inventoryProperty.src, img.file];
        //   this.inventoryProperty.image = [
        //     ...this.inventoryProperty.image,
        //     img.downloadUrl,
        //   ];
      }
    },
    async constructInventoryItem(id) {
      // get inventory property

      let property = portalsApp
        .firestore()
        .collection("inventoryProperties")
        .doc(id);

      property = await property.get();

      if (!property.exists) {
        this.$store.state.loading = false;
        return;
      }
      // bind local property
      const inventory = property.data();
      this.inventoryProperty = {
        ...inventory,
        // resortAddress: inventory.resortAddress,
        // dateUpdated: inventory.dateUpdated,
        // highOccupancySurchargeAmount: inventory.highOccupancySurchargeAmount,
        // archived: inventory.archived,
        // hasHighOccupancySurcharge: inventory.hasHighOccupancySurcharge,
        // resortPropertyId: inventory.resortPropertyId,
        // overview: inventory.overview,
        // minimumProfitAccepted: inventory.minimumProfitAccepted,
        // costOfExtras: inventory.costOfExtras,
        // id: inventory.id,
        // template: inventory.template,
        // source: inventory.source,
        // dateSubmitted: inventory.dateSubmitted,
        // resortName: inventory.resortName,
        // hasPeakSeasonSurcharge1: inventory.hasPeakSeasonSurcharge1,
        // peakSeasonSurcharge1Range: inventory.peakSeasonSurcharge1Range,
        // peakSeasonSurchargeAmount: inventory.peakSeasonSurchargeAmount,
        // taxesFeesPercentage: inventory.taxesFeesPercentage,
        // commissionsPaidPercentage: inventory.commissionsPaidPercentage,
        // peakSeasonSurcharge2Range: inventory.peakSeasonSurcharge2Range,
        // hasPeakSeasonSurcharge2: inventory.hasPeakSeasonSurcharge2,
        // destination: inventory.destination,

        unitInfo: inventory.unitInfo || "",
        airportInfo: inventory.airportInfo
          ? typeof inventory.airportInfo == "string"
            ? this.unWrapFromHtml(inventory.airportInfo).join("\n")
            : inventory.airportInfo.join("\n")
          : "",

        activities: inventory.activities
          ? typeof inventory.activities == "string"
            ? this.unWrapFromHtml(inventory.activities).join("\n")
            : inventory.activities.join("\n")
          : "",

        restrictions: inventory.restrictions
          ? typeof inventory.restrictions == "string"
            ? this.unWrapFromHtml(inventory.restrictions).join("\n")
            : inventory.restrictions.join("\n")
          : "",

        amenities: inventory.amenities
          ? typeof inventory.amenities == "string"
            ? this.unWrapFromHtml(inventory.amenities)
            : inventory.amenities
          : "",

        restaurants: inventory.restaurants
          ? typeof inventory.restaurants == "string"
            ? this.unWrapFromHtml(inventory.restaurants).join("\n")
            : inventory.restaurants.join("\n")
          : "",

        image:
          typeof inventory["image-src"] == "string"
            ? [
                {
                  name: inventory["image-src"],
                  downloadUrl: inventory["image-src"],
                },
              ]
            : inventory["image-src"],
        images:
          typeof inventory["image-src"] == "string"
            ? [
                {
                  name: inventory["image-src"],
                  downloadUrl: inventory["image-src"],
                },
              ]
            : inventory["image-src"],

        checkInInfo: inventory.checkInInfo
          ? typeof inventory.checkInInfo == "string"
            ? this.unWrapFromHtml(inventory.checkInInfo).join("\n")
            : inventory.checkInInfo.join("\n")
          : "",

        otherInfo: inventory.otherInfo
          ? typeof inventory.otherInfo == "string"
            ? this.unWrapFromHtml(inventory.otherInfo).join("\n")
            : inventory.otherInfo.join("\n")
          : "",
      };
    },
    unWrapFromHtml(htmlText) {
      // Create a temporary element to parse the HTML
      if (htmlText === "") return htmlText;
      const tempElement = document.createElement("div");
      tempElement.innerHTML = htmlText;

      // Extract the text within the <p> tags
      const paragraphs = Array.from(tempElement.getElementsByTagName("p")).map(
        (p) => p.textContent
      );

      // return the array of containe paragraphs
      return paragraphs;
    },
    // wrapInHtml(header, array) {
    //   console.log("type", typeof array);
    //   let html = `<h3 class="info-header">${header}</h3><div class="info-item">${
    //     typeof array === "object"
    //       ? array.map((el) => `<p>${el}</p>`)
    //       : `<p>${array}</p>`
    //   }</div>`
    //     .split(",")
    //     .join("");
    //   console.log("html", html);
    //   return html;
    // },
    async checkResortId() {
      // checks to see if resort id already exists in DB
      if (!this.inventoryProperty.id) return;
      this.resortIdError = false;
      this.resortIdErrorMessage = "";

      let docRef = portalsApp
        .firestore()
        .collection("inventoryProperties")
        .doc(this.inventoryProperty.id.toUpperCase());
      let doc = await docRef.get();
      if (doc.exists) {
        this.resortIdError = true;
        this.resortIdErrorMessage = "Resort Id Already Exists";
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "error",
          text: "Resort Id already exists",
          timeout: 10000,
        });
      }
    },
    removeEmptyStringProperties(obj) {
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop) && obj[prop] === "") {
          delete obj[prop];
        }
      }
    },
    async save() {
      // form validation
      if (!this.$refs.form.validate()) {
        await this.$store.dispatch("setLoading", false);
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "error",
          text: "Please check form for errors",
          timeout: 10000,
        });
        return;
      }
      if (!this.inventoryProperty.images.length) {
        await this.$store.dispatch("setLoading", false);
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "error",
          text: "Please upload an image",
          timeout: 10000,
        });
        return;
      }
      await this.$store.dispatch("setLoading", true);
      let inventoryPropertyData = { ...this.inventoryProperty };
      if(Object.hasOwn(inventoryPropertyData, 'commissionsPaidPercentage')) inventoryPropertyData.commissionsPaidPercentage = Number(inventoryPropertyData.commissionsPaidPercentage)
        if(Object.hasOwn(inventoryPropertyData, 'costOfExtras')) inventoryPropertyData.costOfExtras = Number(inventoryPropertyData.costOfExtras)
        if(Object.hasOwn(inventoryPropertyData, 'gardenUnitUpgrade')) inventoryPropertyData.gardenUnitUpgrade = Number(inventoryPropertyData.gardenUnitUpgrade)
        if(Object.hasOwn(inventoryPropertyData, 'highOccupancySurchargeAmount')) inventoryPropertyData.highOccupancySurchargeAmount = Number(inventoryPropertyData.highOccupancySurchargeAmount)
        if(Object.hasOwn(inventoryPropertyData, 'minimumProfitAccepted')) inventoryPropertyData.minimumProfitAccepted = Number(inventoryPropertyData.minimumProfitAccepted)
        if(Object.hasOwn(inventoryPropertyData, 'peakSeasonSurchargeAmount')) inventoryPropertyData.peakSeasonSurchargeAmount = Number(inventoryPropertyData.peakSeasonSurchargeAmount)
        if(Object.hasOwn(inventoryPropertyData, 'taxesFeesPercentage')) inventoryPropertyData.taxesFeesPercentage = Number(inventoryPropertyData.taxesFeesPercentage)
      let fieldsArr = [
        "activities",
        "airportInfo",
        "checkInInfo",
        "otherInfo",
        "restaurants",
        "restrictions",
      ];
      fieldsArr.forEach((el) => {
        if (
          inventoryPropertyData.hasOwnProperty(el) &&
          inventoryPropertyData[el] !== ""
        )
          inventoryPropertyData[el] = inventoryPropertyData[el].split("\n");
      });
      let inventoryListingData = { ...this.inventoryProperty };

      if (!this.editing) {
        // proceed with inventory creation

        // image update to db and get pdfURL
        for (let i = 0; i < inventoryPropertyData.images.length; i++) {
          let image = inventoryPropertyData.images[i];
          let imageRef = mainApp.storage().ref(`inventorys/${image.name}`);
          let imageUploaded = await imageRef.put(image.file);
          image.downloadUrl = await imageUploaded.ref.getDownloadURL();
          delete image.file;
        }
        // let image = this.inventoryProperty.src;
        // let imageRef = mainApp.storage().ref(`inventorys/${image.name}`);
        // let imageUploaded = await imageRef.put(image);
        // inventoryPropertyData.src = await imageUploaded.ref.getDownloadURL();
        // delete image.file;

        // add inventory to inventoryProperties
        let inventoryDocRef = portalsApp
          .firestore()
          .collection("inventoryProperties")
          .doc(inventoryPropertyData.id.toUpperCase());

        //delete src property and save pdfURL in "image-src" property
        const src = [...inventoryPropertyData.images];
        inventoryPropertyData["image-src"] = src;
        inventoryPropertyData.template = "inventoryProperties";
        delete inventoryPropertyData.src;
        delete inventoryPropertyData.image;
        delete inventoryPropertyData.images;

        inventoryPropertyData.dateSubmitted = moment().format("YYYY-MM-DD");
        inventoryPropertyData.dateUpdated = moment().format("YYYY-MM-DD");
        inventoryPropertyData.resortPropertyId = inventoryPropertyData.id;

        await inventoryDocRef.set(inventoryPropertyData);

        // add inventory to inventoryListing
        const inventoryPropertyListing = {
          destination: inventoryPropertyData.destination,
          id: inventoryPropertyData.id,
          dateUpdated: moment().format("YYYY-MM-DD"),
          dateSubmitted: moment().format("YYYY-MM-DD"),
          items: [],
          resortName: inventoryPropertyData.resortName,
          source: inventoryPropertyData.source,
          template: "inventoryListings",
        };

        let listingDocRef = portalsApp
          .firestore()
          .collection("inventoryListings")
          .doc(inventoryPropertyListing.id.toUpperCase());

        await listingDocRef.set(inventoryPropertyListing);
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "success",
          text: "Inventory created successfully",
          timeout: 7000,
        });
      } else {
        // proceed with inventory update
        // if (inventoryPropertyData.src.length) {
        // image update to db and get pdfURL
        for (let i = 0; i < inventoryPropertyData.images.length; i++) {
          let image = inventoryPropertyData.images[i];
          if (image.downloadUrl.toLowerCase().includes("blob")) {
            let image = inventoryPropertyData.images[i];
            let imageRef = mainApp.storage().ref(`inventorys/${image.name}`);
            let imageUploaded = await imageRef.put(image.file);
            image.downloadUrl = await imageUploaded.ref.getDownloadURL();
            delete image.file;
          }
        }
        // let image = this.inventoryProperty.src;
        // let imageRef = mainApp.storage().ref(`inventorys/${image.name}`);
        // let imageUploaded = await imageRef.put(image);
        // inventoryPropertyData.src = await imageUploaded.ref.getDownloadURL();
        // delete image.file;

        //delete src property and save pdfURL in "image-src" property
        const src = [...inventoryPropertyData.images];
        inventoryPropertyData["image-src"] = src;
        delete inventoryPropertyData.images;
        delete inventoryPropertyData.src;

        // }

        this.removeEmptyStringProperties(inventoryPropertyData);
        // add inventory to inventoryProperties
        let inventoryDocRef = portalsApp
          .firestore()
          .collection("inventoryProperties")
          .doc(inventoryPropertyData.id.toUpperCase());

        delete inventoryPropertyData.image;
        inventoryPropertyData.dateUpdated = moment().format("YYYY-MM-DD");
        await inventoryDocRef.update(inventoryPropertyData);

        // add inventory to inventoryListing
        const inventoryPropertyListing = {
          destination: inventoryListingData.destination,
          id: inventoryListingData.id,
          resortName: inventoryListingData.resortName,
          source: inventoryListingData.source,
          dateUpdated: moment().format("YYYY-MM-DD"),
          template: "inventoryListings",
        };

        let listingDocRef = portalsApp
          .firestore()
          .collection("inventoryListings")
          .doc(inventoryPropertyListing.id.toUpperCase());

        await listingDocRef.update(inventoryPropertyListing);
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "success",
          text: "Inventory updated successfully",
          timeout: 7000,
        });
      }
      await this.$store.dispatch("setLoading", false);
      this.dialog = false;
      this.$router.push("/inventory");
    },
  },
};
</script>
<style>
/* .combobox-field .theme--light.v-label {
    color: rgba(0, 0, 0, 0.6);
    background: #fff;
    z-index: 1;
  }
  .combobox-field .v-select__selections {
    height: 130px;
    overflow: auto;
  }
  .combobox-field .v-text-field--outlined fieldset {
    z-index: 1;
  }
  .sticky-v-card-actions {
    z-index: 9;
  } */
</style>
